class AuthInstance {
  constructor() {
    this.auth = null;
    this.config = {
      tenant: auth0Tenant,
      domain: auth0Domain,
      alias: auth0Alias,
      cdn: auth0CDN,
      clientID: auth0ClientID,
      tokenIssuer: auth0TokenIssuer,
    };
  }

  init() {
    if (this.auth) return;
    try {
      if (!Authentication) return;
      this.auth = new Authentication({ config: this.config });
    } catch (e) {
      throw e;
    }
  }
}

let authInst = null;

export const getAuthInst = () => {
  if (!authInst) {
    authInst = new AuthInstance();
    try {
      authInst.init();
    } catch (e) {
      throw e;
    }
  }
  return authInst;
};
