import React from 'react';

const CurbLogo = () => (
        <h1>
            <img src="https://cdn.gocurb.com/img/curb_logo.png" alt="Curb Logo"
                 className="curb-logo"/>
            Business
        </h1>
);

export default CurbLogo;
