import React from 'react';
import '@styles/AuthenticationApp.sass';
import CurbLogo from './CurbLogo';
import AuthForm from './AuthForm';

const AuthenticationApp = () => (
  <div className="site-wrapper-inner">
    <div className="cover-container">
      <div className="cover">
        <div className="cover-content">
          <CurbLogo />
          <AuthForm />
        </div>
      </div>
    </div>
  </div>
);

export default AuthenticationApp;
