import React from 'react';
import { ALERT_TYPE } from './AuthForm';

const AlertMessage = ({ type, close, message }) => {
  const classList = type === ALERT_TYPE.error
    ? 'alert alert-info clearfix fade in'
    : 'alert alert-success clearfix fade in';

  return (
        <div className={classList}>
            <a className="close" onClick={() => close()}>&times;</a>
            <span className="message">{message}</span>
        </div>
  );
};

export default AlertMessage;
