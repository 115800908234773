/* eslint-disable no-restricted-globals,no-console */
import React, { useState, useEffect, useCallback } from 'react';
import { getAuthInst } from '@services/authService';
import AlertMessage from './AlertMessage';

export const ALERT_TYPE = {
  error: 'error',
  success: 'success',
};

const AuthForm = () => {
  const defaultAlert = {
    message: null,
    type: null,
  };

  const messageList = useCallback(() => {
    return {
      notVerifiedMessage:
        'Thank you for requesting an invitation to Curb Business. Our staff is working hard to complete the setup process for your account.',
      notEmailVerifiedMessage:
        'Thank you for requesting an invitation to Curb Business. Please check your email to validate your address.',
      emailVerifiedMessage:
        'Thank you for verifying your email address. Our staff is working hard to complete the setup process for your account.',
      wrongEmailAddress: 'Something went wrong verifying your email address.',
      wrongPassword: 'Something went wrong verifying your password.',
      changePassword: 'Please check your email for instructions to change your password.',
      noAccess: 'Sorry, you do not have access to this site.',
    };
  }, []);

  const [alert, setAlert] = useState(defaultAlert);
  const [authInst] = useState(getAuthInst());
  const [hash] = useState(window.location.hash || null);

  const setAlertHandler = useCallback(() => {
    const message = Utils.getUrlParameter('message');
    if (!message) return;
    console.log(`Message is ${message}`);
    if (message.indexOf('verified') > -1) {
      console.log('Email verification request');
      const success = Utils.getUrlParameter('success');
      history.pushState(null, '', location.href.split('?')[0]);
      const alertObj = {};
      if (success.indexOf('true') === 0) {
        alertObj.message = messageList.emailVerifiedMessage;
        alertObj.type = ALERT_TYPE.success;
      } else {
        alertObj.message = messageList.wrongEmailAddress;
        alertObj.type = ALERT_TYPE.error;
      }
      setAlert(alertObj);
    }
  }, [messageList]);
  const loginProcessHandler = useCallback(() => {
    authInst.auth.getLock();
  }, [authInst]);
  const loggedInHandler = useCallback(
    event => {
      const {
        detail: { user },
      } = event || {};
      const { email_verified: emailVerified, user_metadata: { tos_viewed_at: tosViewedAt } = {} } =
        user;
      if (emailVerified && tosViewedAt !== null) {
        if (Utils.checkRole('corporate_admin')) {
          window.location.href = '/app/dashboard.html';
        } else {
          setAlert({ message: messageList.noAccess, type: ALERT_TYPE.error });
          authInst.auth.logout();
        }
      } else {
        setAlert({
          message: messageList.notEmailVerifiedMessage,
          type: ALERT_TYPE.error,
        });
      }
    },
    [authInst, messageList],
  );
  const loginFailedHandler = useCallback(
    event => {
      const {
        detail: { error: mainErr, error: { code, error, description: errorDescription } = {} },
      } = event || {};
      let msg = 'Your account may have a problem.';
      switch (code) {
        case 'not_staff_verified':
          msg = messageList.notVerifiedMessage;
          break;
        case 'not_email_verified':
          msg = messageList.notEmailVerifiedMessage;
          break;
        case 'change_password':
          msg = messageList.changePassword;
          break;
        case 'invalid_user_password':
          msg = messageList.wrongPassword;
          console.log(`Error: ${error || mainErr} - ${errorDescription}`);
          break;
        default:
          msg = 'Unknown error';
          console.log(`Unknown error: ${error} - ${errorDescription}`);
      }
      setAlert({
        message: msg,
        type: ALERT_TYPE.error,
      });
    },
    [messageList],
  );
  const addEventListeners = useCallback(() => {
    const { unauthenticated, loggedIn, loginFailed } = authInst.auth.eventList;
    authInst.auth.eventHandler.subscribe(window, unauthenticated, loginProcessHandler);
    authInst.auth.eventHandler.subscribe(window, loggedIn, loggedInHandler);
    authInst.auth.eventHandler.subscribe(window, loginFailed, loginFailedHandler);
  }, [authInst.auth, loginProcessHandler, loggedInHandler, loginFailedHandler]);
  useEffect(() => {
    if (!hash) return;
    authInst.auth.locationHashChanged(hash);
  }, [hash, authInst.auth]);
  useEffect(() => {
    setAlertHandler();
  }, [alert, setAlertHandler]);
  useEffect(() => {
    if (!authInst.auth || hash) return;

    authInst.auth.isLoggedIn();
  }, [hash, authInst.auth, addEventListeners]);
  useEffect(() => {
    if (!authInst.auth) return;

    addEventListeners();
  }, [authInst, addEventListeners]);

  if (hash) return <></>;

  return (
    <form id="auth-form" className="form-inline" method="post">
      <div className="form-group buttons">
        <button
          type="button"
          className="btn btn-primary action-login"
          onClick={e => {
            e.preventDefault();
            authInst.auth.isLoggedIn();
          }}
        >
          Log In
        </button>
      </div>
      {alert.message && (
        <AlertMessage
          message={alert.message}
          type={alert.type}
          close={() => setAlert(defaultAlert)}
        />
      )}
    </form>
  );
};

export default AuthForm;
